<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('commande.commandes') }}</h2>
    </div>

    <!--el-row :gutter="20">
      <el-col :span="8">
        <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
          <div slot="header" class="clearfix">
            <span>{{ $t('general.filter') }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
          </div>
          <div class="filter-container">
            <el-radio v-model="activeList" label="actives" border @change="getSubCommandesList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
            <el-radio v-model="activeList" label="desactives" border @change="getSubCommandesList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
            <el-radio v-model="activeList" label="disponibles" border @change="handleFilterCommandes">{{ $t('general.disponibles') }}</el-radio>
            <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubCommandesList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
          </div>
        </el-card>
        <el-row>
          <el-col :span="12" style="margin: 10px;">
            <vs-card class="success-card" @click="havingType('donor')">
              <template #title>
                <h3>{{ $t('general.donors') }}</h3>
              </template>
              <template #text>
                <h2><count-to :start-val="0" :end-val="donorsCount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </el-col>
          <el-col :span="12" style="margin: 10px;">
            <vs-card class="warning-card" @click="havingType('recipient')">
              <template #title>
                <h3>{{ $t('general.recipients') }}</h3>
              </template>
              <template #text>
                <h2><count-to :start-val="0" :end-val="recipientsCount" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16">
        <el-card class="box-card">
          <div class="filter-container">
            <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterCommandes" />
            <el-select v-model="query.type" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('general.type')" style="width: 100%;" @change="handleFilterCommandes">
              <el-option v-for="(type, index) in types" :key="index" :label="type | uppercaseFirst" :value="type" />
            </el-select>
            <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterCommandes">
              {{ $t('general.search') }}
            </el-button>
            <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
              {{ $t('general.export') }}
            </el-button>
            <el-button v-if="checkPermission(['create commande'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreateCommande">
              {{ $t('general.add') }}
            </el-button>
          </div>
          <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">

            <el-table-column align="center" :label="$t('discussion.with')" min-width="250px">
              <template slot-scope="scope">
                <div class="full-width">
                  <div class="half-photo">
                    <div class="demo-basic--circle">
                      <div>
                        <el-avatar shape="square" :size="50" :src="scope.row.avatar" />
                      </div>
                    </div>
                  </div>
                  <div class="half-right">
                    <div class="in-td">
                      <h4>{{ scope.row.name }}</h4>
                      <small>{{ scope.row.type }}</small>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column align="center" :label="$t('commande.organ')" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.organ }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" :label="$t('general.status')" width="100">
              <template slot-scope="scope">
                <el-tag :type="scope.row.is_active ? 'success' : 'danger'">{{ scope.row.is_active ? $t('general.enabled') : $t('general.disabled') }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
              <template slot-scope="scope">
                <el-switch
                  v-if="!scope.row.is_visible"
                  style="display: block"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                  :value="scope.row.is_visible"
                  @change="setCommandeStatus(scope.row.id,'visible')"
                />
              </template>
            </el-table-column>

            <el-table-column v-if="checkPermission(['edit commande','hide commande','delete commande'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
              <template slot-scope="scope">
                <el-button v-if="scope.row.is_visible && checkPermission(['edit commande'])" type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="handleEditCommande(scope.row.id);" />
                <el-button v-if="checkPermission(['hide commande','delete commande'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteCommande(scope.row.id, scope.row.is_visible, scope.row.name);" />
              </template>
            </el-table-column>
          </el-table>
          <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getCommandesList" />
        </el-card>
      </el-col>
    </el-row-->
    <!-- ======= REGION USER DIALOG ======= -->
    <el-drawer
      :title="$t('commande.commande')"
      :visible.sync="dialogCreateCommande"
      :before-close="handleCloseCommandeCreateDraw"
      :loading="commandeCreating"
      size="35%"
    >
      <div class="content-inputs" style="padding:30px">
        <!--=============== USER CARD ==============-->
        <commande-card :commande="currentCommande" :all="true" />
        <!--=============== END USER CARD ==============-->
        <div class="commande-story margin-top">
          <h3>{{ $t('commande.story') }}</h3>
          <p class="story-text">
            {{ currentCommande.story }}
          </p>
        </div>
      </div>
    </el-drawer>

    <!--el-dialog
      :title="editing ? $t('commande.edit') + ' ' + currentCommande.name : $t('commande.AddCommande')"
      :visible.sync="dialogCreateCommande"
      :before-close="handleCloseCommandeCreateDraw"
      :loading="commandeCreating"
      width="70%"
    >
      <div class="content-inputs">
        <el-form ref="commandeForm" :rules="rules" :model="currentCommande" label-position="left">
          <el-form-item :label="$t('general.type')" prop="type">
            <el-select v-model="currentCommande.type" class="filter-item" :placeholder="$t('general.type')" style="width: 100%;" prop="type">
              <el-option
                v-for="(type, idex) in types"
                :key="idex"
                :label="type | uppercaseFirst"
                :value="type"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('commande.organ')" prop="organ">
            <el-input v-model="currentCommande.organ" />
          </el-form-item>
          <el-form-item :label="$t('commande.story')" prop="story">
            <el-input v-model="currentCommande.story" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="dialogCreateCommande=false">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="commandeCreating" @click="editing ? updateCommande() : createCommande()">
            {{ commandeCreating ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </span>
    </el-dialog-->

    <!-- ======= REGION USER DIALOG ======= -->

  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
// import commandeCard from './components/CommandeCard';
import moment from 'moment';
// import CountTo from 'vue-count-to';
const commandeResource = new Resource('commandes');
export default {
  name: 'CommandesList',
  // components: { Pagination, commandeCard, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      donorsCount: 0,
      recipientsCount: 0,
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      commandeCreating: false,
      editing: false,
      activeList: 'disponibles',
      types: this.$commandeTypes,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: '',
      },
      currentCommande: {},
      dialogCreateCommande: false,
      currentCommandeId: 0,
      rules: {
        organ: [{ required: true, message: this.$t('commande.OrganRequired'), trigger: 'blur' }],
        story: [{ required: true, message: this.$t('commande.BodyRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.resetNewCommande();
    this.getCommandesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCommandeCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelCommandeForm() {
      this.$refs.CommandeCreationDrawer.closeDrawer();
    },
    havingType(type){
      this.query.type = type;
      this.handleFilterCommandes();
    },
    getTypeCount(type){
      var count = 0;
      this.list.forEach((commande) => {
        if (commande.type === type){
          count++;
        }
      });
      return count;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterCommandes();
    },
    async getCommandesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await commandeResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
      this.donorsCount = this.getTypeCount('donor');
      this.recipientsCount = this.getTypeCount('recipient');
    },
    async getSubCommandesList(subUrl) {
      this.loading = true;
      const { data } = await commandeResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterCommandes() {
      this.query.page = 1;
      this.getCommandesList();
    },
    handleCreateCommande() {
      this.editing = false;
      this.resetNewCommande();
      this.dialogCreateCommande = true;
      /* this.$nextTick(() => {
        this.$refs['commandeForm'].clearValidate();
      });*/
    },
    handleEditCommande(id){
      this.editing = true;
      this.resetNewCommande();
      this.currentCommande = this.list.find(abonne => abonne.id === id);
      this.dialogCreateCommande = true;
      /* this.$nextTick(() => {
        this.$refs['commandeForm'].clearValidate();
      });*/
    },
    async setCommandeStatus(id, type){
      const data = await commandeResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteCommande(id, visible, name) {
      this.$confirm(this.$t('commande.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['admin']) || checkPermission(['delete commande']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              commandeResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('commande.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setCommandeStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createCommande() {
      this.$refs['commandeForm'].validate((valid) => {
        if (valid) {
          this.currentCommande.roles = [this.currentCommande.role];
          this.commandeCreating = true;
          commandeResource
            .store(this.currentCommande)
            .then(async(response) => {
              this.$message({
                message: this.$t('commande.NewCommande') + ' ' + this.currentCommande.organ + ' ' + this.$t('commande.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentCommandeId = response.data.id;
              this.resetNewCommande();
              this.dialogCreateCommande = false;
              this.handleFilterCommandes();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.commandeCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateCommande() {
      this.$refs['commandeForm'].validate((valid) => {
        if (valid) {
          this.commandeCreating = true;
          commandeResource.update(this.currentCommande.id, this.currentCommande).then(response => {
            this.resetNewCommande();
            this.handleFilterCommandes();
            this.$message({
              message: this.$t('commande.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateCommande = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.commandeCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewCommande() {
      this.currentCommande = {
        organ: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('commande.organ'), this.$t('commande.story')];
        const filterVal = ['id', 'organ', 'story'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Commandes list SOYCAIN_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
